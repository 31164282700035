import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const CreateTournamentForm = Loadable(lazy(() => import('./Create')))
const TournamentList = Loadable(lazy(() => import('./List')))
const TournamentManage = Loadable(lazy(() => import('./TournamentManage')))
const TournamentUpdate = Loadable(lazy(() => import('./TournamentUpdate')))
const TournamentRound = Loadable(lazy(() => import('./TournamentRound')))
const TournamentGroup = Loadable(lazy(() => import('./TournamentGroup')))
const TournamentMatches = Loadable(lazy(() => import('./tournamentMatches')))
const ManageAccess = Loadable(lazy(() => import('../Admin/ManageAccess')))


const tournamentRoutes = [
    {
        path: '/tournament/create',
        element: <CreateTournamentForm />,
    },
    {
        path: '/tournament/all',
        exact: true,
        element: <TournamentList />,
    },
    {
        path: '/tournament/:tournamentId',
        element: <TournamentManage />,
    },
    {
        path: '/tournament/:tournamentId/round/:roundNumber',
        element: <TournamentRound />,
    },
    {
        path: `/tournament/update/:id`,
        element: <TournamentUpdate />,
    },
    {
        path: `/tournament/:tournamentId/round/:roundNumber/group/:groupId`,
        element: <TournamentGroup />,
    },
    {
        path: `/access/`,
        element: <ManageAccess />,
    },
    {
        path: `/tournament/:tournamentId/:bracketId/roundNumber/:roundId`,
        element: <TournamentMatches />,
    },
]

export default tournamentRoutes
