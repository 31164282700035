import '../fake-db'
import React, { useEffect } from 'react'
import { Store } from './redux/Store'
import { Provider } from 'react-redux'
import { AllPages } from './routes/routes'
import { MatxTheme } from 'app/components'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { SnackbarProvider } from 'notistack'
import axios from 'axios'

// const token =
//     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMDdiODczZTkxZTNjZTkyNWY0NzExNiIsImlhdCI6MTY0NTI5NzY2NywiZXhwIjoxNjQ3ODg5NjY3fQ.zq9E2-38hQR_nPOHAelQQ_X_-Ga_9C3DltRYQWhh65E'

const App = () => {
    const all_pages = useRoutes(AllPages())
    //     const [logged, setLogged] = useState(false)
    // console.log(user)
    useEffect(() => {
        const accessToken = window.localStorage.getItem('token')
        axios.defaults.baseURL = 'https://cm.3not3.com/api'
        if (accessToken) {
            axios.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${accessToken}`
        }
    }, [])

    // set axios default url
    // axios.defaults.baseURL = 'http://localhost:5000/api'
    return (
        <Provider store={Store}>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SettingsProvider>
                    <MatxTheme>
                        <AuthProvider>{all_pages}</AuthProvider>
                    </MatxTheme>
                </SettingsProvider>
            </SnackbarProvider>
        </Provider>
    )
}

export default App
