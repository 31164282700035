import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('token', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('token')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    axios.defaults.baseURL = 'https://cm.3not3.com/api'
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        const response = await axios.post('/auth/login', {
            email,
            password,
        })
        if (response.data.user.userType === 101) {
            return Promise.reject({
                message: 'You are not authorized to login.',
            })
        }
        const { token, user } = response.data
        localStorage.setItem('token', token)
        localStorage.setItem('authUser', JSON.stringify(user))
        setSession(token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const token = localStorage.getItem('token')
                const userData = JSON.parse(localStorage.getItem('authUser'))
                console.log(userData)
                // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMDdiODczZTkxZTNjZTkyNWY0NzExNiIsImlhdCI6MTY0NTI5NzY2NywiZXhwIjoxNjQ3ODg5NjY3fQ.zq9E2-38hQR_nPOHAelQQ_X_-Ga_9C3DltRYQWhh65E"

                if (token && isValidToken(token)) {
                    // if (true) {
                    setSession(token)
                    axios.defaults.headers.common[
                        'Authorization'
                    ] = `Bearer ${token}`
                    const response = await axios.get(
                        `/user/profile/${userData.username}`
                    )
                    const { user } = response.data

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: user,
                        },
                    })
                } else {
                    console.log('no token')
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error('boom', err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
